import React, { useState, useEffect, useRef } from "react";
import Table from "../common/tables/ActionTable";
import { BreadCrumbs } from "../common/layout";
import { Helmet } from "react-helmet";
import authService from "../../../src/services/authService";
import entityService from "../../services/entityService";
import { CountriesList, BPList } from '../slplusTools';
import { Picker, ViewButton } from "../common/inputs";
import { Link } from "react-router";

import "../../styles/react-table.css"
import "react-table/react-table.css";

function AllMembers() {
    
    const tableRef = useRef();

    const { constants, category } = authService.getCurrentUser();

    const [searchKeyword, setSearchKeyword] = useState("");
    const [country, setCountry] = useState(null);
    const [bp, setBp] = useState(null);
    const [accountType, setAccountType] = useState("all");
    const [businessType, setBusinessType] = useState("all");
    const [showExport, setShowExport] = useState(false);
    const [tableParams, setTableParams] = useState([]);

    const VerificationStatusBadge = ({status}) => {

        const getBadgeStyle = (status) => {
            switch(status){
                case 'not_verified' :
                    return "danger";
                case 'verified' :  
                    return 'primary';
                case 'pending' :
                    return 'warning'; 
                default :
                    return 'primary';          
            }
        }

        const formatStatusText = (status) => {
            const words = status.split('_');
            return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
        };

        return(
            <span className={`badge badge-${getBadgeStyle(status)}`}>{formatStatusText(status)}</span>
        );
    }

    const handleSearchKeywordChange = async (event) => {
        await setSearchKeyword(event.target.value);
        tableRef.current.reloadData()
    }
    const handleBpChange = async (optionVal) => {
        await setBp(optionVal === null ? "all" : optionVal);
        tableRef.current.reloadData()
    }
    const handleAccountTypeChange = async (optionVal) => {
        await setAccountType(optionVal);
        tableRef.current.reloadData()
    }
    const handleBusinessTypeChange = async (optionVal) => {
        await setBusinessType(optionVal);
        tableRef.current.reloadData()
    }

    const accountTypes = [
        { label: "All Accounts", value: "all" },
        { label: "Primary Accounts", value: "primary" },
        { label: "Secondary Accounts", value: "secondary" }
    ]

    const businessTypes = [
        { label: "All", value: "all" },
        { label: "Corporate", value: "Corporate" },
        { label: "Network", value: "Network" }
    ]
   
    const columns = [
        {
            Header: "Organization",
            accessor: "organization",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.organization}</span>,
        },
        {
            Header: "First Name",
            accessor: "first_name",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.first_name}</span>,
        },
        {
            Header: "Last Name",
            accessor: "last_name",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.last_name}</span>,
        },
        {
            Header: "Email",
            accessor: "email",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.email}</span>,
        },
        {
            Header: "Phone No",
            accessor: "phone_no",
            headerStyle: { textAlign: "left" },
            Cell: (row) => <span title={row.original.error}>{row.original.phone_no}</span>,
        },
        {
            Header: "User Access",
            accessor: "user_access",
            headerStyle: { textAlign: "left" },
            minWidth: 50,
            Cell: (row) => (
                <span
                  title={row.original.error}
                  className={`badge badge-${
                    row.original.has_user_account === "Yes" ? "primary" : "danger"
                  }`}
                >
                  {row.original.has_user_account}
                </span>
              ),
        },
        {
            Header: "Phone Verification",
            accessor: "phone_verification",
            headerStyle: { textAlign: "left" },
            minWidth: 50,
            Cell: (row) => <VerificationStatusBadge status={row.original.phone_verification_status}/>,
        },
        {
            Header: "Action",
            accessor: "action",
            headerStyle: { textAlign: "left" },
            minWidth: 50,
            Cell: row => {
                return (
                    <>
                        <Link
                            className="action-icons"
                            to={{ pathname: `/entities/members/profile`, search: "?contact_id=" + row.original.contact_id }}
                        >
                            <ViewButton></ViewButton>
                        </Link> &nbsp;
                    </>
                )
            }
        },
    ];

    const exportExcel = async () => {
        const res = await entityService.listAllMembers({
            ...tableParams,
            pageSize: null,
            download: true
        });
        
        const newWindow = window.open(res.data);
        newWindow.opener = null;
      };

    const ActionButtons = () => (
        <>
         {showExport && <Link className="btn btn-outline-secondary pull-right" onClick={exportExcel} label="Export" >Export</Link> }
        </>
    );

    return (
        <>
            <Helmet>
                <title>All Team Members | {global.config.title}</title>
            </Helmet>
            <BreadCrumbs Header="All Team Members" activeModule="All Team Members" Route1="/dashboard"
            Label1="Dashboard" /> <br />

            <>
                <div className="">
                    <h4 className="card-title m-2">Filter</h4>
                    <div className="row">
                        <div className="col-lg-3">
                            <input
                                type="text"
                                placeholder="Search by Name / Phone No. / Email"
                                value={searchKeyword}
                                onChange={handleSearchKeywordChange}
                                className="form-control"
                            />
                        </div>
                        
                        {
                            category == constants.CATEGORY.INTL_OFFICE && (
                                <>
                                    <div className="col-lg-3">
                                        <CountriesList
                                            type="vertical"
                                            selectedCountry={country}
                                            onSelectItem={({ id }) => setCountry(id)}
                                        />
                                    </div>
                                </>

                            )
                        }

                        {
                            (category == constants.CATEGORY.INTL_OFFICE || category == constants.CATEGORY.COUNTRY_OFFICE) && (
                                <>
                                    <div className="col-lg-3">
                                        <BPList
                                            selectedBp={bp}
                                            type="vertical"
                                            country={country}
                                            onSelectItem={({ value }) => handleBpChange(value)}
                                        />
                                    </div>
                                </>
                            )

                        }

                        <div className="col-lg-3">
                            <div className="form-group">
                                <Picker
                                    items={accountTypes}
                                    selectedItem={accountTypes.find(item => item.value === accountType)}
                                    onSelectItem={({ value }) => handleAccountTypeChange(value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <Picker
                                    items={businessTypes}
                                    selectedItem={businessTypes.find(item => item.value === businessType)}
                                    onSelectItem={({ value }) => handleBusinessTypeChange(value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
            

            <div className="card overflow-visible">
                <div className="card-header">
                    <Table
                        ref={tableRef}
                        service={entityService.listAllMembers}
                        cols={columns}
                        tableRightPanel={<ActionButtons />}
                        onDataLoad={(data, tableParams) => { // Callback function to trigger after populating data on Action table
                            setShowExport(data.rows.length > 0 ? true : false);
                            setTableParams(tableParams);
                        }}
                        reqParams={{
                            bp,
                            searchKeyword,
                            accountType,
                            businessType
                        }}
                    />
                </div>
            </div>    
        </>
    );
}

export default AllMembers;
