import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../../../common/loaders/loadingSpinner";
import { GeneralButton, TextInput } from "../../../common/inputs";

import entityService from "../../../../services/entityService";
import authService from "../../../../services/authService";
import coreService from "../../../../services/coreService";
import useApi from "../../../../hooks/useApi";
import useForm from "../../../../hooks/useForm";
import { toast } from "react-toastify";
import Joi from "joi-browser";
import { confirmAlert } from "react-confirm-alert";
import { Link, browserHistory } from "react-router";

function EditAccount() {

  const [verificationOTP, setVerificationOTP] = useState(null);
  //------------Api Services--------------------------------------------
  const contactApi = useApi(entityService.getEntityContactsList);
  const verifyReqApi = useApi(coreService.initiateNumberVerification);
  const validateReqApi = useApi(coreService.validatePhoneNumber);
  const profileCorrectionApi = useApi(coreService.requestProfileCorrection);
  const { entity_id, contact_id } = authService.getCurrentUser();

  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      first_name: Joi.string()
        .required()
        .error(() => {
          return { message: "Please enter a First Name" };
        }),
      phone_no: Joi.string()
        .required()
        .label("Phone No.")
        .error(() => {
          return { message: "Please enter a Phone No." };
        }),
    })
    .unknown(true);

  //------------Api Services--------------------------------------------
  const entityContactApi = useApi(entityService.createEntityContact);

  useEffect(() => {
    if (verificationOTP)
      renderOtpInput()
  }, [verificationOTP])

  // ----------------------------------------------------------------
  const initiateVerification = async () => {
    try {

      setVerificationOTP("");
      const response = await verifyReqApi.request();

      if (response.data?.success) {
        renderOtpInput();
      } else {
        toast.error(response.data?.message || 'Something went wrong while processing your request.');
      }
    } catch (error) {
      toast.error('Network error. Please try again.');
    }
  };

  // ----------------------------------------------------------------
  const handleOTPValidation = async (onClose) => {
    onClose();
    try {
      const response = await validateReqApi.request({ otp: verificationOTP });
      if (response.data?.success) {
        toast.success(response.data?.message);
        fetchEdit();
      } else {
        renderOtpInput(response.data?.message || 'Something went wrong.');
      }
    } catch (error) {
      renderOtpInput('Failed to validate OTP. Please try again.');
    }
  };

  // ----------------------------------------------------------------
  const renderOtpInput = (failedMsg = null) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h6>Verify Profile</h6>
            <p>{'An OTP has been sent to your registered mobile number. Please enter it here to complete the verification process.'}</p>
            <TextInput
              name="otp"
              label="Please enter the OTP"
              value={verificationOTP}
              onChange={({ target }) => setVerificationOTP(target.value)}
              type="vertical"
            />

            {failedMsg && (
              <p className="mt-2 bm-2 text-danger">
                <strong>{failedMsg}</strong>
              </p>
            )}

            <button
              className="btn btn-white ml-2"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={() => handleOTPValidation(onClose)}
            >
              Verify
            </button>
            <div>
              <span
                onClick={() => {
                  onClose();
                  initiateVerification();
                }}
                style={{ cursor: "pointer" }}
                className="text-primary pull-right"
              >
                Resend OTP
              </span>
            </div>
          </div>
        );
      },
      title: "Confirm OTP",
      message: "Are you sure you want to proceed with OTP validation?",
    });
  };

  // -----------------------------------------------------------
  const raiseProfileCorrectionRequest = async (onClose) => {
    onClose();
    try {
      const response = await profileCorrectionApi.request();
      if (response.data?.success) {
        toast.success(response.data?.message);
      } else {
        toast.error(response.data?.message || 'Something went wrong.');
      }
    } catch (error) {
      toast.error('Something went wrong while processing your request.');
    }
  };
  // -----------------------------------------------------------
  const handleProfileCorrectionRequest = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-delete-ui">
            <h6>Request to correct profile information</h6>
            <p>Please confirm if you would like to raise a request to correct your profile information.</p>
           
            <button
              className="btn btn-white ml-2"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={() => {
                raiseProfileCorrectionRequest(onClose)
              }}
            >
              Confirm
            </button>
          </div>
        );
      },
    });
  }

  //------------------Custom hook to handle form data-----------------------------
  const { values, setValues, handleChange, handleSubmit } = useForm(
    initiateVerification,
    schema,
  ); // Custom Hook to handle Form Validation

  const fetchEdit = async () => {
    const { data } = await contactApi.request(entity_id);
    let rows = data.rows;
    let updatedContact = rows.find((obj) => obj.contact_id == contact_id);
    setValues({ ...values, ...updatedContact });
  };
  useEffect(() => {
    fetchEdit();
  }, []);

  return (
    <>
      <Helmet>
        <title>My Account | {global.config.title}</title>
      </Helmet>
      {(contactApi.loading || verifyReqApi.loading || validateReqApi.loading || profileCorrectionApi.loading) && <LoadingSpinner />}

      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-6">
              <h6>Update Profile Details</h6>
            </div>
            <div className="col-lg-6">
              <Link
                className="btn btn-light pull-right text-secondary ml-2 cursor-link"
                onClick={() => browserHistory.goBack()}
              >
                <i
                  alt="Go to Dashboard"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Scf Dashboard"
                  className="fa fa-close"
                ></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-8 col-lg-12 m-auto text-center "></div>
            <div className="col-lg-12 m-auto text-center ">
              <h3 className="font-weight-bold ">
                <div className="avatar avatar-lg bg-secondary  mx-auto mb-3 mb-sm-0 rounded-full text-white">
                  <span>
                    {values.first_name && values.first_name.charAt(0)}
                  </span>
                </div>
              </h3>

              <p>
                <i className="fa fa-envelope mr-2"></i>
                {values.email}
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-6 p-4   ">
              <form onSubmit={handleSubmit} className="form-horizontal">
                <TextInput
                  name="first_name"
                  label="First Name"
                  value={values.first_name}
                  onChange={handleChange}
                  type="vertical"
                  class="form-control"
                  readonly
                ></TextInput>

                <TextInput
                  name="last_name"
                  label="Last Name"
                  value={values.last_name}
                  onChange={handleChange}
                  type="vertical"
                  readonly
                ></TextInput>
                <TextInput
                  name="phone_no"
                  label="Phone"
                  value={values.phone_no}
                  onChange={handleChange}
                  type="vertical"
                  readonly
                ></TextInput>

                {values.phone_verification_status &&
                  <div className="row mt-5 mb-5">
                    <div className="col-lg-12">
                      <span onClick={handleProfileCorrectionRequest} className="label bg-danger-transparent text-danger">
                        Not Correct?{" "}
                        <a className="text-danger font-weight-bold">Notify Us</a>
                      </span>

                      {values.phone_verification_status === 'verified' ? 
                        <span className="label bg-primary-transparent text-primary float-right">
                          Phone number verified
                        </span> : 
                        <GeneralButton
                          className="btn btn-primary float-right"
                          faIcon="fa fa-save"
                          name="Verify Profile"
                          disabled={verifyReqApi.loading}
                      ></GeneralButton>
                      }
                    </div>
                  </div>
                }

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditAccount;