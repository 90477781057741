import React, { useState, useEffect } from "react";
import { BreadCrumbs } from "../common/layout";
import { Helmet } from "react-helmet";
import authService from "../../../src/services/authService";
import entityService from "../../services/entityService";
import { Picker, GeneralButton, TextInput } from "../common/inputs";
import { FormContainer } from "../common/containers/formContainer";
import useApi from "../../hooks/useApi";
import queryString from "query-string";
import Joi from "joi-browser";
import useForm from "../../hooks/useForm";
import { getInitials } from "../../utils/NameInitials";
import { toast } from "react-toastify";
import LoadingSpinner from "../common/loaders/loadingSpinner";
import { Link } from "react-router";


function ProfileSettings(props) {
    const { location } = props;
    const { contact_id } = queryString.parse(location.search);
    const { constants, category } = authService.getCurrentUser();

    const [loader, setLoader] = useState(false);

    //------------------Validation Schema-----------------------------
    const profileSchema = Joi.object().keys({
        first_name: Joi.string().required().label("First Name"),
        phone_no: Joi.string().required().label("Phone No."),
    }).unknown(true);

    const settingsSchema = Joi.object().keys({
        default_mfa: Joi.string().required().label("MFA Type"),
    }).unknown(true);

    const mfaTypes = [
        { label: "None", value: "none" },
        { label: "OTP", value: "otp" },
        { label: "Authenticator", value: "authenticator" }
    ];


    const contactApi = useApi(entityService.getMemberByContactId);
    const updateContactApi = useApi(entityService.updateEntityContact);

    //----------- Profile Update Function ------------------//
    const updateProfile = async () => {
        const profileData = {
            contact_id,
            first_name: profileValues.first_name,
            last_name: profileValues.last_name,
            phone_no: profileValues.phone_no
        };

        const { data } = await updateContactApi.request(profileData);
        if (data.success) {
            toast.success("Profile updated successfully");
            fetchProfileDetails();
        } else {
            toast.error(data.message);
        }
    };

    //----------- Settings Update Function ----------------//
    const updateSettings = async () => {
        const { data } = await updateContactApi.request({ default_mfa: settingsValues.default_mfa, contact_id });
        if (data.success) {
            toast.success("Settings updated successfully");
            fetchProfileDetails();
        } else {
            toast.error(data.message);
        }
    };

    const { values: profileValues, handleChange: handleProfileChange, handleSubmit: handleProfileSubmit, setValues: setProfileValues } = useForm(updateProfile, profileSchema);
    const { values: settingsValues, handlePickerChange, handleSubmit: handleSettingsSubmit, setValues: setSettingsValues } = useForm(updateSettings, settingsSchema);

    const fetchProfileDetails = async () => {
        try {
            setLoader(true);
            const { data } = await contactApi.request({ contact_id });
            const contactData = data.data;
            setProfileValues({
                contact_id,
                first_name: contactData.first_name,
                last_name: contactData.last_name,
                phone_no: contactData.phone_no,
                organization: contactData.organization
            });
            setSettingsValues({ default_mfa: contactData.default_mfa });
            setLoader(false);
        } catch (error) {
            toast.error("Failed to fetch profile details");
        }
    };

    useEffect(() => {
        fetchProfileDetails();
    }, []);

    //--------------- Render Components -------------------//
    const ProfileColumn = () => (
        <div className="card">
            <div className="card-header d-sm-flex w-100  py-3 align-items-center">
                <div className="avatar avatar-2xl bg-secondary text-white  mx-auto mb-3 mb-sm-0 rounded-3xl">
                    <span>{getInitials(profileValues.first_name + ' ' + profileValues.last_name, "")}</span>
                </div>
                <div className="w-100 ml-sm-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="font-bold my-0 truncate">{profileValues.first_name + ' ' + profileValues.last_name}</h5>
                        <Link className="btn  btn-light  rounded-full mr-2 btn-closeHeader" title="close and go back to listing" to="/entities/members">
                            <i alt="close this page" class="fa fa-times mr-1"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );

    const ProfileEditColumn = () => (
        <FormContainer title="Profile">
            <form className="form-horizontal" onSubmit={handleProfileSubmit}>
                <TextInput
                    name="first_name"
                    label="First Name"
                    value={profileValues.first_name || ""}
                    onChange={handleProfileChange}
                    type="vertical"
                    readonly
                />
                <TextInput
                    name="last_name"
                    label="Last Name"
                    value={profileValues.last_name || ""}
                    onChange={handleProfileChange}
                    type="vertical"
                    readonly
                />
                <TextInput
                    name="phone_no"
                    label="Phone"
                    value={profileValues.phone_no || ""}
                    onChange={handleProfileChange}
                    type="vertical"
                    readonly
                />
                {/* <div className="row mt-5 mb-5">
                    <div className="col-lg-12">
                        <GeneralButton
                            className="btn btn-primary float-right"
                            faIcon="fa fa-save"
                            name="Save"
                        />
                    </div>
                </div> */}
            </form>
        </FormContainer>
    );

    const SettingsColumn = () => (
        <FormContainer title="Settings">
            <form className="form-horizontal" onSubmit={handleSettingsSubmit}>
                <div className="form-group">
                    <label>Default MFA</label>
                    <Picker
                        items={mfaTypes} 
                        selectedItem={mfaTypes.find(item => item.value === settingsValues.default_mfa)} 
                        onSelectItem={({ value }) => handlePickerChange("default_mfa", value)} 
                    />
                </div>
                <div className="row mt-5 mb-5">
                    <div className="col-lg-12">
                        <GeneralButton
                            className="btn btn-primary float-right"
                            faIcon="fa fa-save"
                            name="Save"
                        />
                    </div>
                </div>
            </form>
        </FormContainer>
    );

    return (
        <>
            {loader == true && <LoadingSpinner />}
            <Helmet>
                <title>Profile Settings | {global.config.title}</title>
            </Helmet>
            <BreadCrumbs Header="Profile Settings" activeModule="Profile Settings" 
                Route1="/dashboard"
                Label1="Dashboard"
                Route2="/entities/members"
                Label2="All Team Members" /> <br />

            <div className="row">
                <div className="col-lg-12">
                    <ProfileColumn />
                </div>
                <div className="col-lg-6">
                    <ProfileEditColumn />
                </div>
                <div className="col-lg-6">
                    <SettingsColumn />
                </div>
            </div>
        </>
    );
}

export default ProfileSettings;
